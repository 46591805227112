import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import NavButtons from 'components/Software/Windows/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Windows Software for your INSTAR IP Camera",
  "path": "/Software/Windows/Zone_Trigger/",
  "dateChanged": "2017-12-05",
  "author": "Mike Polinowski",
  "excerpt": "The Windows app Zone Trigger for your PC or Laptop. Control your camera from your computer and harness the computing power for advanced features.",
  "image": "./P_SearchThumb_Zone_Trigger.png",
  "social": "/images/Search/P_SearchThumb_Zone_Trigger.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Windows-Zone-Trigger_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Zone Trigger' dateChanged='2017-12-05' author='Mike Polinowski' tag='INSTAR IP Camera' description='Windows Software for your INSTAR IP Camera' image='/images/Search/P_SearchThumb_Zone_Trigger.png' twitter='/images/Search/P_SearchThumb_Zone_Trigger.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Windows/Zone_Trigger/' locationFR='/fr/Software/Windows/Zone_Trigger/' crumbLabel="ZoneTrigger" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "windows",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#windows",
        "aria-label": "windows permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Windows`}</h2>
    <h3 {...{
      "id": "zonetrigger",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#zonetrigger",
        "aria-label": "zonetrigger permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ZoneTrigger`}</h3>
    <p>{`The Zone Trigger software series is all about enabling anyone to incorporate motion or sound detection in their custom projects. On detecting motion, Zone Trigger can do a multitude of tasks, such as play sounds, run programs or commands, take control of other software or hardware, send email and files over the internet. Plus, it has many extra features such as archive services and a built in HTTP server for remote viewing.`}</p>
    <p>{`In order to integrate your INSTAR IP camera in `}<a href="http://www.zonetrigger.com/" target="_blank" rel="noopener noreferrer">{`Zone Trigger`}</a>{` we recommend you to download the latest version from `}<a href="http://www.zonetrigger.com/" target="_blank" rel="noopener noreferrer">{`here`}</a>{`.`}</p>
    <h4 {...{
      "id": "add-a-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#add-a-camera",
        "aria-label": "add a camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add a Camera`}</h4>
    <p>{`After you installed the newest version of ZoneTrigger please open the program and open the `}<strong parentName="p">{`Add a New Video Source`}</strong>{` and choose to add an IP camera by URL. Type in the cameras IP address HTTP port but leave the username and password free. Now click on `}<strong parentName="p">{`Specify Path`}</strong>{` and add the line:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`videostream.cgi?user=admin&pwd=`}</code></pre></div>
    <p>{`if your camera still has the default login `}<em parentName="p">{`user = admin`}</em>{` and `}<em parentName="p">{`password = `}{`[empty]`}</em>{` - modify the video path accordingly if you changed something.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "918px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ed87bab3999c1371f09e12e4f979ef60/e0885/Webcam-ZoneTrigger_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAAsTAAALEwEAmpwYAAACFElEQVQozz3OW2vTYBwG8Hws9SvohYoM3LoOZOqFoOCVgp9D5mQtdCDKEC9EmNJD2jdN07xp36TNltTm0KbNqTm3TXdIu0qrFn4X//8DDzxYgxeQaCJhwEumoDprrtjzOn1P7K2Oc2WVCKrDyzYv2evXY0UDCTrWaJ5BmkGcgLgObPA4gCTF5kskSbLFAqRgq1ShazR3+oM4PPj8/jD75eR75uj468k3xAkYz4sMQj8rdIES89R5pSkDpJYbEtnSKF4n2F6R/l1pKmVGJjmNQGqVVfF87ddpHkIOy2RzHz5mzIGpykpHFFVFdh1nHEXJ9fU8ScZBqEiyoRvjMIqCyLGdq9nF5WwBqnUIWWwnlb577/5yuTQMU1EVXdcty4rjOEmSm+UynsShH4Z+OAknvhv4bhB6QeDHgKhDmsX2nz5/8HBLlfqq0ut2JUmSh0N9ZDu+F8TTmTEwEIPabBsxiGXYNssLvNDtav/KueNPhRLQFG0cRvF0Op1Mri4ub+aLeZKsZ4+HvYE5MAxNt4aG3FWaismoLg6o1eyXr17vP3sxnS0sO7SdyHYiyw5H7tgahUJHVfqG2O33NMvzYz+YeX7selPTict/y2/evrt9687u3pPtVHo7ld7Z3Xu09fgom0PcWRmv4v8BQAFAVat1gqAIggI4YJgWVqsx5VKlVMA3ivkSSdRgHVJVaqO2QayQgISN9h8kM+VLr1uRMQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ed87bab3999c1371f09e12e4f979ef60/e4706/Webcam-ZoneTrigger_01.avif 230w", "/en/static/ed87bab3999c1371f09e12e4f979ef60/d1af7/Webcam-ZoneTrigger_01.avif 460w", "/en/static/ed87bab3999c1371f09e12e4f979ef60/6d4a2/Webcam-ZoneTrigger_01.avif 918w"],
              "sizes": "(max-width: 918px) 100vw, 918px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ed87bab3999c1371f09e12e4f979ef60/a0b58/Webcam-ZoneTrigger_01.webp 230w", "/en/static/ed87bab3999c1371f09e12e4f979ef60/bc10c/Webcam-ZoneTrigger_01.webp 460w", "/en/static/ed87bab3999c1371f09e12e4f979ef60/56bc7/Webcam-ZoneTrigger_01.webp 918w"],
              "sizes": "(max-width: 918px) 100vw, 918px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ed87bab3999c1371f09e12e4f979ef60/81c8e/Webcam-ZoneTrigger_01.png 230w", "/en/static/ed87bab3999c1371f09e12e4f979ef60/08a84/Webcam-ZoneTrigger_01.png 460w", "/en/static/ed87bab3999c1371f09e12e4f979ef60/e0885/Webcam-ZoneTrigger_01.png 918w"],
              "sizes": "(max-width: 918px) 100vw, 918px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ed87bab3999c1371f09e12e4f979ef60/e0885/Webcam-ZoneTrigger_01.png",
              "alt": "Windows App Zone Trigger",
              "title": "Windows App Zone Trigger",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Add a new video source by URL.`}</p>
    <h4 {...{
      "id": "motion-detection",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#motion-detection",
        "aria-label": "motion detection permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Motion Detection`}</h4>
    <p>{`ZoneTrigger, as the software name might suggest, employs trigger zones that you can freely position on top of the video. The default is a round shaped hot spot that uses a software based motion detection to trigger an alarm event. This is the simplest and most versatile technique. The software looks for any difference between frames by comparing pixel colour and brightness without trying to identify anything. These differences can be caused by moving objects, lights on/off, shadows, and even vibrations. You can quickly assign an action to an event like recording a video, uploading a snapshot to an FTP server or sending a notification email.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "915px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/872103f863131fac6008a2645258a427/4255a/Webcam-ZoneTrigger_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.17391304347826%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAAsTAAALEwEAmpwYAAACOUlEQVQozz3Ly04TURgA4L6KS+NTuEBjUKMbokQX6MKoC7wrJKjEgFK0C8UYY4hAW2AsFGg7tNOWmXP7z1zO6cxgO6VASyNoCSZN3KAxMWhc6Lf/Qq6/7nnVarVRrTUrQb3k18qVupDloFJ3ZS1Ya0i3Ug42OXbnE7nEXCqXQ6lMHmNzNpEOmcAFJ7bJXUo9zkxk2MAoQpwwaoAFnGFqMjA0Yzmtqal0USskZpViVpuKK6GESuOqjcFZydOkyj6kcWzJKBYpJ4AMrBcMrBOkY4tbwpGOJSywbW770puMKaF3scTt6WAotzewtNOnbNwcFzfeGuNTC3oaYUSAgLSlsIQrXEY5RoRTThGVlpiMKqE3ryMDD2/dV7xJ1JyF7Yj2ZXhOxOJJtIyBAtYxw0zTCwRTTsEEk1POMJRs+TcPDj09debESPheNHIpEr7+5NXLsaQ+l9E4ozaYDjdtAE+K9SDY2mxIpwQEgPzLV3t7j3d2nOs+drrryNGThzo6D1++1pNMZcofV/M6Uws4jzi1fBBlKX3HtIEA+5+Hng0/uHul52J3f9+d0fDjF88fjYz2Kcq0sDzs+GZQr7XaW+39rfZ3bnsMAyMmQSAs8T6qhJBB8qqaWVhcXlwg+Rwt5PSsSrUCVY0VWM2YG1m5LT/9WN09EI7vA/U5d4GulaQyPROaMdyYs56qf52v7U5UWmN+Kyxbg9bnftjJuhuNemOv/W3/56/fBwdhfedstHkh3uya2Dw/UT82wv8AI3avyVI23vcAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/872103f863131fac6008a2645258a427/e4706/Webcam-ZoneTrigger_02.avif 230w", "/en/static/872103f863131fac6008a2645258a427/d1af7/Webcam-ZoneTrigger_02.avif 460w", "/en/static/872103f863131fac6008a2645258a427/6e770/Webcam-ZoneTrigger_02.avif 915w"],
              "sizes": "(max-width: 915px) 100vw, 915px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/872103f863131fac6008a2645258a427/a0b58/Webcam-ZoneTrigger_02.webp 230w", "/en/static/872103f863131fac6008a2645258a427/bc10c/Webcam-ZoneTrigger_02.webp 460w", "/en/static/872103f863131fac6008a2645258a427/632b0/Webcam-ZoneTrigger_02.webp 915w"],
              "sizes": "(max-width: 915px) 100vw, 915px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/872103f863131fac6008a2645258a427/81c8e/Webcam-ZoneTrigger_02.png 230w", "/en/static/872103f863131fac6008a2645258a427/08a84/Webcam-ZoneTrigger_02.png 460w", "/en/static/872103f863131fac6008a2645258a427/4255a/Webcam-ZoneTrigger_02.png 915w"],
              "sizes": "(max-width: 915px) 100vw, 915px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/872103f863131fac6008a2645258a427/4255a/Webcam-ZoneTrigger_02.png",
              "alt": "Windows App Zone Trigger",
              "title": "Windows App Zone Trigger",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Choose an Alarm Action.`}</p>
    <p>{`There are a lot more Triggers, beside the simple Motion Detection that can be employed to your surveillance task. Like Flow Control which determines in which direction people are travelling, a Level Measure or a Pattern Matching that tries to match the input image to a reference image to figure out the state of certain objects.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "915px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9daed2f2bdbdb055b02f91b8e69c594f/4255a/Webcam-ZoneTrigger_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAAsTAAALEwEAmpwYAAACN0lEQVQozz3PwU/TYBzG8f0bHvwnvHk3MSZoomCC8YAXY8QYCWggmoUADoMYA0YGGnBuMMciG+sodd3o2vf9/d72bd+2o2UbAxREDODNxKMHDMaYPPnePocnIhOhYEumjZK5XXX3jdoBhMcQHuHGMWyclgaHEB5BeKj7B7r/nQSHrPHD9r8WyEZEiCCoBa3m9lZrp1Fv2dxb9wMEc90NbPDCIOSWHdQCWoL0u2wqmSrkpA8LGTm3LLlbkaoOnFGTASupjlFFTWMADqIFDCljFIEgEgQdDB2oThlhaqmCFS1vBZH5xPRYohxLEA3Wp3NuWuYzH8lYoryq6MxgaxXNqBJqADXAFZ5tCRMtbtousiUrjMSivQPjc3cntdeVveEF3pNsdsfN7qlKOiURhYBBODDfFsLiwuSgE6TIwBIUclYYmXkZfTrYF5t8FV9U1gz2YRWfyXujGVKUFGawFZ1LVbuocQX9NaeuuU2DCQZMUDzFsxMjnTc7+2+3FZPDieTE8pv+F2l5VnXy+VW9rNue7wWhJVzHrzV3Pv8+Oak3WtQAAX/xk8GRC22X2jsuR++c7+4409F+9uKVc9Hx56padbmn2XWj9kVzNvXabvDt1+7PE6e2Cf/xw4FHt7quPbjXdeP61ZHB3v7H94eGemKjfbIkmcRWDa7otkqEU99zNw/s+j6aHqP473MmuzQ79zaVSsbjU9nF+ZVCNp/LpNLvi4VPWhksNE04HRJgBE2KJnJExwErxxt/APQ4q9Z2PpIzAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9daed2f2bdbdb055b02f91b8e69c594f/e4706/Webcam-ZoneTrigger_03.avif 230w", "/en/static/9daed2f2bdbdb055b02f91b8e69c594f/d1af7/Webcam-ZoneTrigger_03.avif 460w", "/en/static/9daed2f2bdbdb055b02f91b8e69c594f/6e770/Webcam-ZoneTrigger_03.avif 915w"],
              "sizes": "(max-width: 915px) 100vw, 915px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9daed2f2bdbdb055b02f91b8e69c594f/a0b58/Webcam-ZoneTrigger_03.webp 230w", "/en/static/9daed2f2bdbdb055b02f91b8e69c594f/bc10c/Webcam-ZoneTrigger_03.webp 460w", "/en/static/9daed2f2bdbdb055b02f91b8e69c594f/632b0/Webcam-ZoneTrigger_03.webp 915w"],
              "sizes": "(max-width: 915px) 100vw, 915px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9daed2f2bdbdb055b02f91b8e69c594f/81c8e/Webcam-ZoneTrigger_03.png 230w", "/en/static/9daed2f2bdbdb055b02f91b8e69c594f/08a84/Webcam-ZoneTrigger_03.png 460w", "/en/static/9daed2f2bdbdb055b02f91b8e69c594f/4255a/Webcam-ZoneTrigger_03.png 915w"],
              "sizes": "(max-width: 915px) 100vw, 915px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9daed2f2bdbdb055b02f91b8e69c594f/4255a/Webcam-ZoneTrigger_03.png",
              "alt": "Windows App Zone Trigger",
              "title": "Windows App Zone Trigger",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You have a variety of advanced triggers available beside the standard motion detection.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      